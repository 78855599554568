<template>
  <div id="attendance">
    <div class="card height-auto v-application v-application--is-ltr">
      <div class="card-body">
        <!-- Kids Cards -->
        <v-row class="kids">
          <v-col
            cols="6"
            sm="3"
            class="py-0 mb-4"
            v-for="(student, index) in studentInfo"
            :key="index"
          >
            <v-row
              class="kid"
              :class="student.active == true ? 'active' : ''"
              @click="MakeHimActive(index)"
            >
              <v-col cols="4">
                <img
                  :src="student.logo"
                  v-if="student.logo"
                  alt="student image"
                  class="student-image"
                />
                <img
                  v-else
                  src="../../assets/avatar.png"
                  class="student-image"
                  alt=""
                />
              </v-col>
              <v-col cols="8">
                <p class="student-name">{{ student.name }}</p>
              </v-col>
              <v-col cols="4" class="py-0">
                <p class="student-grade">{{ student.grade }}</p>
              </v-col>

              <v-col cols="8" class="py-0">
                <p class="text-right student-class">{{ student.class }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- End Kids Cards -->
        <!-- Filters -->
        <v-row>
          <!-- Date Piker -->
          <v-col cols="4">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Select Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="menu = false" :max="date"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!-- state -->
          <v-col cols="4">
            <v-select
              :items="state"
              v-model="selectedState"
              item-text="text"
              item-value="id"
              label="State"
              solo
              @change="getStudentAttendance()"
            ></v-select
          ></v-col>
          <!-- subjects -->
          <v-col cols="4">
            <v-select
              v-model="selectedSubjects"
              :items="subjects"
              item-text="name"
              item-value="id"
              @change="getStudentAttendance()"
              chips
              label="subject"
              multiple
              solo
            ></v-select>
          </v-col>
        </v-row>
        <!-- end Filters -->
        <!-- table -->
        <v-row>
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      class="text-left"
                      v-for="(item, index) in headers"
                      :key="index"
                    >
                      {{ item }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(lessons, date) in items" :key="date">
                    <td>{{ date }}</td>
                    <td
                      v-for="(lessonObject, lessonIndex) in lessons"
                      :key="lessonIndex"
                    >
                      <p
                        class="subjectName mb-0"
                        v-if="
                          selectedState == '' ||
                            (selectedState != '' && lessonObject.absent != null)
                        "
                      >
                        {{ lessonObject.subject_name }}

                        <v-menu
                          offset-x
                          max-width="300px"
                          v-if="
                            lessonObject.comment && lessonObject.subject_name
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small v-bind="attrs" v-on="on">
                              <v-icon color="#7297ff">mdi-alert-circle</v-icon>
                            </v-btn>
                          </template>
                          <v-list style="padding-top: 0">
                            <v-list-item style="background-color: gray">
                              <p class="mb-0" style="color: #ffffff">
                                Teacher Comment
                              </p>
                            </v-list-item>
                            <v-list-item>
                              <p class="mb-0">
                                {{ lessonObject.comment }}
                              </p>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </p>
                      <v-icon
                        v-if="
                          lessonObject.absent == 0 && lessonObject.subject_name
                        "
                        color="#7297ff"
                        >check</v-icon
                      >
                      <v-icon
                        v-if="
                          lessonObject.absent == 1 && lessonObject.subject_name
                        "
                        color="error"
                        >close</v-icon
                      >
                      <span
                        v-if="
                          lessonObject.absent == null &&
                            lessonObject.subject_name &&
                            selectedState == ''
                        "
                        class="notAvailable"
                        >N/A</span
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <!-- end table -->
        <!-- <v-row> -->
        <!-- <v-col cols="12" sm="3"> -->
        <!-- <v-row> -->
        <!-- <v-col cols="4" sm="4" class="text-right">
                  <label for="Date" class="label">Date:</label>
                </v-col> -->
        <!-- <v-col cols="8" sm="8" id="attendancemenu">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="selectedDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    :disabled="edit"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="selectedDate"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="selectedDate" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false"
                        >Cancel</v-btn
                      >
                      <v-btn text color="primary" @click="saveDate">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                  
                </v-col> -->
        <!-- </v-row> -->
        <!-- </v-col> -->
        <!-- <v-col cols="12" sm="3" v-if="childs.length > 1">
              <v-row>
                <v-col cols="4" sm="4" class="text-right">
                  
                  <label for="Childs" class="label">Childs:</label>
                </v-col>
                <v-col cols="8" sm="8">
                
                  <v-select
                    :items="childs"
                    item-text="name"
                    item-value="id"
                    v-model.trim="selectedChild"
                    solo
                    @change="getLessons"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="3" v-if="attendances.length != 0">
              <v-row>
                <v-col cols="6" sm="6" class="text-right">
                  
                  <label for="Childs" class="label">Class Name :</label>
                </v-col>
                <v-col cols="4" sm="4">
                  <label for="Childs" class="label">{{
                    this.attendances[0]["class_name"]
                  }}</label>
                </v-col>
              </v-row>
            </v-col> -->
        <!-- </v-row> -->
        <!-- <table class="table table-attendance" v-if="attendances.length != 0">
          <thead>
            <tr>
              <th>Student Name</th>
              <th v-for="(lesson, index) in lessons" :key="index">
                {{ lesson.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in attendances" :key="index">
              <td>{{ item.student_name }}</td>
              <td
                v-for="(lesson, lessonIndex) in item.lessons"
                :key="lessonIndex"
              >
                <v-icon
                  v-if="lesson['value'] && edit == false"
                  color="#5fb870"
                  title="Attendant"
                  >check</v-icon
                >
                <v-icon
                  v-if="!lesson['value'] && edit == false"
                  color="error"
                  title="Attendant"
                  >close</v-icon
                >
                <v-checkbox
                  v-model="lesson['value']"
                  v-if="edit == true"
                ></v-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="attendances.length == 0">
          <v-row>
            <v-col cols="12" md="12">
              <v-alert class="info_alert">{{
                $t("There is no data available")
              }}</v-alert>
            </v-col>
          </v-row>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "classAttendance",
  data() {
    return {
      date: '',
      childs: [],
      selectedChild: [],
      edit: false,
      singleSelect: false,
      selected: [],
      attendances: [],
      classes: [],
      dates: [],
      lessons: [],
      selectedClass: "",
      selectedDate: "",
      selectedLesson: "",
      menu: false,
      state: [
        {
          text: "",
          id: ""
        },
        {
          text: this.$i18n.t("Absent"),
          id: "absent"
        },
        {
          text: this.$i18n.t("Attended"),
          id: "attended"
        }
      ],

      selectedState: "",
      subjects: [],
      selectedSubjects: [],
      studentInfo: [],
      selectedStudent_id: "",
      headers: [],
      items: [],
      date: "",
      modal: false
    };
  },
  watch: {
    date: {
      handler() {
        this.getStudentAttendance();
        this.getSubjects();
      }
    },
    selectedStudent_id: {
      handler() {
        this.getStudentAttendance();
        this.getSubjects();
      },
      deep: true
    }
  },
  methods: {
    MakeHimActive(index) {
      if (this.studentInfo[index].active != true) {
        this.studentInfo.forEach(student => {
          student.active = false;
        });
        $(".kid").removeClass("active");
        this.studentInfo[index].active = true;
        $(".kid")
          .eq(index)
          .addClass("active");
        this.selectedStudent_id = this.studentInfo[index].student_id;
        this.filter.student_ids = [];
        this.filter.student_ids.push(this.selectedStudent_id);
        if (this.studentInfo[index].class != "") {
          this.getStudentAttendance();
          this.selectedChild = this.selectedStudent_id;
          this.getSubjects();
          this.getDates();
        } else {
          this.items = [];
          this.headers = [];
        }
      } else {
        this.filter.student_ids = [];
        this.filter.student_ids.push(this.selectedStudent_id);
        if (this.studentInfo[index].class != "") {
          this.getStudentAttendance();
          this.getSubjects();
          this.getDates();
        } else {
          this.items = [];
          this.headers = [];
        }
      }
    },
    getMyChildsInfo() {
      axios
        .get(this.getApiUrl + "/dashboard/parent", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.studentInfo = response.data.data.student_info;

          this.studentInfo.forEach(student => {
            student.active = false;
          });
          this.studentInfo[0].active = true;
          this.selectedStudent_id = this.studentInfo[0].student_id;
          this.MakeHimActive(0);
        });
    },
    getStudentAttendance() {
      axios
        .post(
          this.getApiUrl + "/attendance/getStudentsAttendanc",
          {
            student_id: this.selectedStudent_id,
            date: this.date,
            state: this.selectedState,
            subject: this.selectedSubjects
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          if (response.data.status.error == false) {
            this.items = response.data.data.attendence;
            this.headers = response.data.data.lessons;
          }
        });
    },
    saveDate() {
      this.$refs.menu.save(this.selectedDate);
      this.getLessons();
      //   this.getAttendances();
      //   this.getClasses();
    },
    async getLessons() {
      await axios
        .get(
          this.getApiUrl +
            "/attendance/getLessonsOfstudent?student_id=" +
            this.selectedChild +
            "&date=" +
            this.selectedDate,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          // this.lessons = response.data.data;
          // this.headersAdmin = [];
          this.getAttendances(response.data.data);
        });
    },
    getDates() {
      axios
        .get(this.getApiUrl + "/attendance/getDatesForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.dates = response.data.data.dates;
          this.selectedDate = response.data.data.default;
        });
    },
    async getAttendances(lessons = null) {
      if (this.selectedChild != "" && this.selectedDate != "") {
        await axios
          .get(
            this.getApiUrl +
              "/attendance?student_id=" +
              this.selectedChild +
              "&date=" +
              this.selectedDate,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token
              }
            }
          )
          .then(response => {
            this.attendances = response.data.data;
            this.lessons = lessons;
          });
      }
    },
    getSubjects() {
      axios
        .get(
          this.getApiUrl +
            "/attendance/getSubjectsOfstudentSchedule?student_id=" +
            this.selectedStudent_id,

          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          if (response.data.status.error == false) {
            this.subjects = response.data.data;
            // console.log(response.data.data);
          }
        });
    }
  },
  mounted() {
    this.getMyChildsInfo();
    this.getDates();
    if ((new Date().getMonth() + 1) < 10) {
      this.date = new Date().getFullYear() + '-0' + (new Date().getMonth() + 1) + '-' + new Date().getDate();
    } else {
      this.date = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.label {
  margin-top: 1rem;
}

.class-scroll {
  margin-top: 1rem;
}
.btns {
  margin-top: 1rem;
}

// .table-attendance thead tr:last-child th {
//   font-size: 13px !important;
//   /* font-weight: 700 !important; */
//   border-bottom: none !important;
//   border-top: none !important;
//   padding: 0 5px !important;
//   vertical-align: top;
// }
// .table-attendance thead tr {
//   background-color: transparent !important;
//   border-radius: 0;
//   box-shadow: unset !important;
//   border-bottom: none !important;
//   border-top: none !important;
//   vertical-align: top;
// }

// .table-attendance tbody tr:nth-of-type(odd) {
//   background-color: #eaeefc;
// }
// .table-attendance tr {
//   background-color: #ffffff;
//   box-shadow: #d1d1d1 0px 2px 8px 0px !important;
//   -webkit-box-shadow: #d1d1d1 0px 2px 8px 0px !important;
//   /* filter: drop-shadow(0 3px 0px #D1D1D1); */
//   -webkit-box-decoration-break: clone;
//   -o-box-decoration-break: clone;
//   box-decoration-break: clone;
//   /* box-shadow: 0 0px 20px 0 rgba(0,0,0,0.05); */
//   border-radius: 9px;
//   height: 48px !important;
//   border-bottom: none !important;
//   border-top: none !important;
//   border: none !important;
// }
// .table-attendance {
//   background-color: #ffffff;
//   border-spacing: 0px 8px !important;
//   border-collapse: separate;
//   border-color: transparent;
// }
// .table-attendance td {
//   font-size: 13px !important ;
//   max-width: 200px;
//   padding-top: 10px;
//   padding-bottom: 10px;
//   padding: 0 5px;
//   vertical-align: middle;
// }
// .table-attendance td:first-child {
//   padding-left: 20px;
// }
// .table-attendance thead tr th {
//   color: rgba(0, 0, 0, 0.6);
//   border-bottom: none !important;
//   border-top: none !important;
//   vertical-align: top !important;
// }
// .add-kids {
//   background-color: $main-background;
//   display: block;
//   width: 100%;
//   height: 234px;
//   line-height: 200px;
//   border-radius: 15px;
// }
.notAvailable {
  color: #ffb400;
}
.kids {
  margin-bottom: 20px;
  .kid {
    border: 1px solid #dddddd;
    background-color: #ffffff;
    border-radius: 10px;
    cursor: pointer;

    .student-image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    .student-name {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      color: #000000;
    }
    .student-grade,
    .student-class {
      color: #000000;
      margin-left: 10px;
    }
  }
  .kid.active {
    background-color: #d8e2ff;
    cursor: default;
  }
}
</style>
